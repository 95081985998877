import "./src/styles/global.css";

export const onInitialClientRender = () => {
    const observer = new MutationObserver((mutations, obs) => {
        const main = document.querySelector("main");
        const images = main.querySelectorAll("img");

        images.forEach((img) => {
            const rect = img.getBoundingClientRect();

            // Detect first image near viewport (potential LCP)
            if (rect.top < window.innerHeight * 0.85 && !img.dataset.optimized) {
                img.dataset.optimized = "true"; // Prevent duplicate execution
                
                // Preload to ensure priority
                if (!document.querySelector(`link[rel="preload"][href="${img.src}"]`)) {
                    const link = document.createElement("link");
                    link.rel = "preload";
                    link.as = "image/webp";
                    link.href = img.src;
                    document.head.appendChild(link);
                }

                // Set high priority immediately
                img.setAttribute("loading", "eager");
                img.setAttribute("decoding", "async");
                img.setAttribute("fetchpriority", "high");

                console.log("Preloaded and optimized:", img.src);
            }
        });

        // Stop observing once we have detected an image
        if (images.length > 0) {
            obs.disconnect();
        }
    });

    observer.observe(document.body, { childList: true, subtree: true });
};

export const onRouteUpdate = () => {
    // Detect LCP image early
    const observer = new MutationObserver((mutations, obs) => {
        const main = document.querySelector("main");
        const images = main.querySelectorAll("img");

        images.forEach((img) => {
            const rect = img.getBoundingClientRect();

            // Detect first image near viewport (potential LCP)
            if (rect.top < window.innerHeight * 0.85 && !img.dataset.optimized) {
                img.dataset.optimized = "true"; // Prevent duplicate execution
                
                // Preload to ensure priority
                if (!document.querySelector(`link[rel="preload"][href="${img.src}"]`)) {
                    const link = document.createElement("link");
                    link.rel = "preload";
                    link.as = "image/webp";
                    link.href = img.src;
                    document.head.appendChild(link);
                }

                // Set high priority immediately
                img.setAttribute("loading", "eager");
                img.setAttribute("decoding", "async");
                img.setAttribute("fetchpriority", "high");

                console.log("Preloaded and optimized:", img.src);
            }
        });

        // Stop observing once we have detected an image
        if (images.length > 0) {
            obs.disconnect();
        }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Prioritize viewport images immediately
    document.querySelectorAll("img").forEach((img) => {
        const rect = img.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.5) {
            img.setAttribute("loading", "eager");
            img.setAttribute("decoding", "async");
            img.setAttribute("fetchpriority", "high");
        } else {
            img.setAttribute("loading", "lazy");
            img.setAttribute("decoding", "async");
        }
    });
};
